/* * @Author: liuzhixiang * @Date: 2021-02-06 14:44:50 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-11-We 11:04:32 */
<template>
  <div class="onDemand bgc_ffffff">
    <div class="width_1200 cont">
      <div class="cont_left">
        <div>
          <div id="ppt" style="width: 500px; height: 300px"></div>
          <div id="player">
            <div id="waterMark">
              {{ user.RealName }}
              {{ user.Id }}
            </div>
          </div>
        </div>
        <div class="title">
          <img v-lazy="course.CoverImg" class="cover" alt />
          <div class="rig">
            <div class="rig_top">
              <span>直播</span>
              <span>{{ course.CourseName }}</span>
            </div>
            <div class="rig_bottom">讲师：{{ course.TeacherName }}</div>
          </div>
        </div>
      </div>
      <div class="cont_right">
        <div id="wrap"></div>
      </div>
    </div>
    <camera
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      @close="getClose()"
      @studentface="postStudentCompareFace"
    ></camera>
  </div>
</template>
<script>
import { liveQueryLiveToken, studentSign } from "@/api/personal";
import {
  studentCompareFace,
  sysConfigQueryFaceSetting,
  CheckToken,
} from "@/api/api";
import camera from "@/components/canvasCamera.vue";
export default {
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vid: "fe0eb41d75bf5f037a7faaab47bf7e1c_f", //后台返回的视频ID
      //播放加密视频需要请求加密字符串
      list: [],
      course: {},
      live: {
        appId: "",
        appId: "",
        appId: "",
        appId: "",
        token: "",
        mediaChannelKey: "",
        enableFlower: true,
        enableLike: true,
        enableOnLine: true,
      },
      user: {},
      timer: "",
      timerTwo: "",
      category: 1, //1直播2点播
      rule: {},
      dialogVisible: false,
      onlineUserNumber: 0,
    };
  },
  created() {
    setInterval(() => {
      CheckToken();
    }, 5000);
  },
  components: {
    camera,
  },
  mounted() {
    this.user = JSON.parse(this.$store.state.userInfo);
    this.init();
    this.studentSign();
  },
  methods: {
    async studentSign() {
      this.pageSize = 4;
      let parm = {
        studentId: this.user.userId,
        courseId: this.$route.query.CoursId,
        signMethod: 0,
        SourcePort: "PC",
      };
      const res = await studentSign(parm);
      if (res.success == true) {
      } else {
      }
    },
    getClose() {
      this.dialogVisible = false;
    },
    // 人脸识别
    async postStudentCompareFace(flag) {
      // const formData = new FormData();
      // formData.append("file", file);
      // const res = await studentCompareFace(formData);
      if (flag == true) {
        // this.$message.success(res.msg);
        this.dialogVisible = false;
      } else {
        this.$message.error("识别失败");
        this.dialogVisible = false;
        setTimeout(() => {
          this.$router.push({ path: "/personal/myCourse" });
        }, 1500);
      }
    },
    // 人脸识别规则
    async postSysConfigQueryFaceSetting() {
      const res = await sysConfigQueryFaceSetting(this.category);
      if (res.success == true) {
        this.rule = res.response[0];
        if (this.rule.RuleType == 1) {
          this.dialogVisible = false;
        } else if (this.rule.RuleType == 2) {
          // 定时弹出 间隔时间/识别次数
          let num =
            parseInt(this.rule.TimeInterval) / parseInt(this.rule.ShowCount);
          this.timerTwo = setInterval(() => {
            this.dialogVisible = true;
          }, num * 1000 * 60);
        } else if (this.rule.RuleType == 3) {
          // 随机弹出 最小间隔时间-最大间隔时间
          let num,
            min = parseInt(this.rule.MinRandomTime),
            max = parseInt(this.rule.MaxRandomTime);
          num = this.randomNumber(min, max);
          this.timerTwo = setInterval(() => {
            num = this.randomNumber(min, max);
            // console.log(num, num * 1000)
            this.dialogVisible = true;
          }, num * 1000 * 60);
        }
        // console.log(res.response[0])
      } else {
        this.$message.error(res.msg);
      }
    },
    //随机数
    randomNumber(min, max) {
      return Math.floor(Math.random() * (min - max) + max);
    },
    //列表
    async postLiveQueryLiveToken() {
      this.course = this.$route.query;
      let channelId = this.$route.query.ChannelId;
      const res = await liveQueryLiveToken(channelId);
      if (res.success == true) {
        this.live.appId = res.response.appId;
        this.live.channelId = res.response.channelId;
        this.live.liveSign = res.response.liveSign;
        this.live.timestamp = res.response.timestamp;
        this.live.token = res.response.token;
        this.live.mediaChannelKey = res.response.mediaChannelKey;
        (this.live.enableFlower = res.response.enableFlower),
          (this.live.enableLike = res.response.enableLike),
          (this.live.enableOnLine = res.response.enableOnLine),
          this.lives();
        this.timer = setInterval(() => {
          this.genarateMark();
        }, 3 * 1000);
      } else {
        this.$message.error(res.msg);
      }
    },
    //跑马灯
    genarateMark() {
      let playerDOM = document.getElementById("player"),
        playerWidth = playerDOM?.clientWidth,
        playerHeight = playerDOM?.clientHeight;
      // 添加 50px 偏差以免定位错乱
      let positionX = this.common.getRandom(0, playerWidth - 50),
        positionY = this.common.getRandom(0, playerHeight - 50);

      const markDOM = document.getElementById("waterMark");
      markDOM.style.left = positionX + "px";
      markDOM.style.top = positionY + "px";
    },
    lives() {
      let chatroom = new PolyvChatRoom({
        roomId: this.live.channelId,
        userId: this.user.Id,
        nick: this.user.RealName + this.user.Id,
        pic: "http://livestatic.videocc.net/assets/wimages/missing_face.png",
        token: this.live.token,
        mediaChannelKey: this.live.mediaChannelKey,
        version: "2.0",
        container: "#wrap",
        width: 350,
        height: 600,
        showUserList: this.live.enableOnLine,
        enableFlower: this.live.enableFlower,
        enableLike: this.live.enableLike,
        enableWelcome: false,
        enableBulletin: true,
        userType: "",

        tabData: [
          {
            name: '互动聊天（<span id="usercount"></span>）',
            type: "chat",
          },
          {
            name: "在线",
            type: "user-list",
          },
        ],
        roomMessage: function (data) {
          // TODO
          // data为聊天室socket消息，当有聊天室消息时会触发此方法
          console.log(data.onlineUserNumber);
          this.onlineUserNumber = data.onlineUserNumber;
          console.log(data);
          document.getElementById("usercount").innerHTML =
            data.onlineUserNumber + " 人";
        },
      });
      //直播
      var liveSdk = new PolyvLiveSdk({
        channelId: this.live.channelId,
        sign: this.live.liveSign, // 频道验证签名
        timestamp: this.live.timestamp, // 毫秒级时间戳
        appId: this.live.appId, // polyv 后台的appId
        socket: chatroom.chat.socket,
        user: {
          userId: this.user.Id,
          userName: this.user.RealName + this.user.Id,
          pic: "//livestatic.videocc.net/assets/wimages/missing_face.png",
        },
      });
      // 监听频道信息并初始化播放器
      liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
        liveSdk.setupPlayer({
          pptEl: "#ppt",
          el: "#player",
          type: "auto",
          autoplay: true,
          allowFullscreen: false,
        });
      });
    },
    //引入直播、直播聊天室的js、css
    init() {
      let arr = [
        "https://player.polyv.net/livesdk/polyv-live.min.js",
        "https://player.polyv.net/jssdk/polyv-chatroom.min.js",
      ];
      arr.forEach((item) => {
        let script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", item);
        document.getElementsByTagName("head")[0].appendChild(script);
      });
      let link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute(
        "href",
        "https://player.polyv.net/jssdk/polyv-chatroom.min.css"
      );
      document.getElementsByTagName("head")[0].appendChild(link);

      this.postLiveQueryLiveToken();
      this.postSysConfigQueryFaceSetting();
    },
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timerTwo);
  },
};
</script>
<style lang="less" scoped>
.onDemand {
  // margin-top: -30px;
  .cont {
    color: #ffffff;
    padding: 70px 0;
    display: flex;
    justify-content: space-between;
    min-height: 600px;
    .cont_left {
      background-color: #2d2d2d;
      width: 850px;
      height: 600px;
      #ppt {
        display: none;
      }
      #player {
        width: 850px !important;
        height: 500px !important;
        position: relative;
        /deep/.plv-live-player-container {
          // background-color: #2d2d2d;
        }
        #waterMark {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 999;
          // color: #1890ff;
          color: #6e6e6e;
          display: inline-block;
          font-weight: bold;
        }
      }
      .title {
        height: 100px;
        display: flex;
        align-items: center;
        .cover {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin: 0 20px;
        }
        .rig {
          font-size: 14px;
          .rig_top {
            display: flex;
            align-items: center;
            span {
              height: 24px;
              line-height: 24px;
              display: inline-block;
              &:nth-child(1) {
                width: 44px;
                text-align: center;
                background: #575757;
                border-radius: 4px;
              }
              &:nth-child(2) {
                font-size: 22px;
                margin-left: 20px;
              }
            }
          }
          .rig_bottom {
            margin-top: 20px;
            line-height: 100%;
          }
        }
      }
    }
    .cont_right {
      height: 600px;
      background-color: #131313;
      color: #d8d8d8;
    }
  }
}
</style>
